import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { createProduct } from "../../../functions/product";
import ProductCreateForm from "../../../components/forms/ProductCreateForm";
import { getCategories, getCategorySubs } from "../../../functions/category";
import FileUpload from "../../../components/forms/FileUpload";
import { LoadingOutlined } from "@ant-design/icons";
import { getFormData } from "../utils";

const initialState = {
  title: "",
  description: "",
  categories: [],
  category: "",
  subs: [],
  images: [],
  featurea: "",
  featureb: "",
  featurec: "",
  featured: "",
  designa: "",
  designb: "",
  designc: "",
  designd: "",
  load: "",
  freq: "",
  min: "",
  max: "",
};

const ProductCreate = () => {
  const [values, setValues] = useState(initialState);
  const [files, setFiles] = useState({ brochure: null, datasheet: null });
  const [subOptions, setSubOptions] = useState([]);
  const [showSub, setShowSub] = useState(false);
  const [loading, setLoading] = useState(false);

  //redux
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () =>
    getCategories().then((c) => setValues({ ...values, categories: c.data }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = getFormData(values);
    formData.append("brochure", files.brochure);
    formData.append("datasheet", files.datasheet);
    createProduct(formData, user.token)
      .then((res) => {
        console.log(res);
        window.alert(`"${res.data.title}" is created`);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.err);
      });
  };

  const handleFileChange = (e) => {
    setFiles({ ...files, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    e.preventDefault();
    console.log("CLICKED CATEGORY", e.target.value);
    setValues({ ...values, subs: [], category: e.target.value });
    getCategorySubs(e.target.value).then((res) => {
      console.log("SUB OPTIONS ON CATEGORY CLICK", res);
      setSubOptions(res.data);
    });
    setShowSub(true);
  };

  return (
    <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-danger mb-3">Admin</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <AdminNav />
          </div>
          <div className="col-md-10">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Product create</h4>
            )}
            <hr />
            <div className="p-3">
              <FileUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>
            <ProductCreateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleFileChange={handleFileChange}
              setValues={setValues}
              values={values}
              handleCategoryChange={handleCategoryChange}
              subOptions={subOptions}
              showSub={showSub}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCreate;
