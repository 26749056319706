import React, { useState, useEffect } from "react";
import { getCategory } from "../../functions/category";
import ProductCard from "../../components/cards/ProductCard";

const CategoryHome = ({ match }) => {
  const [category, setCategory] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const { slug } = match.params;

  useEffect(() => {
    setLoading(true);
    getCategory(slug).then((res) => {
      setCategory(res.data.category);
      setProducts(res.data.products);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mt-5 mb-5">Loading...</h4>
            ) : (
              <h1 className="text-danger mt-n5 mb-5">{category.name}</h1>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 pt-2">
            <h4>Product Filter</h4>
          </div>

          <div className="col-md-9 pt-2">
            <div className="row pb-5">
              {products.map((p) => (
                <div key={p._id} className="col-md-4 mt-3">
                  <ProductCard product={p} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryHome;
