import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getSolutions } from "../../../functions/solution";
import { updateSubsol, getSubsol } from "../../../functions/subsol";
import CategoryForm from "../../../components/forms/CategoryForm";

const SubsolUpdate = ({ match, history }) => {
  const { user } = useSelector((state) => ({ ...state }));
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [parent, setParent] = useState("");

  useEffect(() => {
    loadSolutions();
    loadSubsol();
  }, []);

  const loadSolutions = () => getSolutions().then((u) => setSolutions(u.data));

  const loadSubsol = () =>
    getSubsol(match.params.slug).then((b) => {
      setName(b.data.name);
      setParent(b.data.parent);
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    updateSubsol(match.params.slug, { name, parent }, user.token)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        toast.success(`"${res.data.name}" is updated`);
        history.push("/admin/sub-solution");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  return (
    <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-danger mb-3">Admin</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <AdminNav />
          </div>
          <div className="col">
            {loading ? (
              <h4 className="text-danger">Loading...</h4>
            ) : (
              <h4>Update sub solution category</h4>
            )}

            <div className="form-group">
              <label>Parent Solution</label>
              <select
                name="solution"
                className="form-control"
                onChange={(e) => setParent(e.target.value)}
                value={parent}
              >
                <option>Please select</option>
                {solutions.length > 0 &&
                  solutions.map((u) => (
                    <option
                      key={u._id}
                      value={u._id}
                      selected={u._id === parent}
                    >
                      {u.name}
                    </option>
                  ))}
              </select>
            </div>
            <CategoryForm
              handleSubmit={handleSubmit}
              name={name}
              setName={setName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubsolUpdate;
