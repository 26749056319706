import React from "react";
import { Breadcrumb } from "antd";

const CookiePolicy = () => {
  return (
    <>
      <div className="container-fluid p-0 mt-5">
        <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-danger mb-5">Cookie Policy</h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb className="mt-2">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/privacy-policy">
                <span>Privacy Policy</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/terms-of-use">
                <span>Terms of Use</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Cookie Policy
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pb-3">
          <div className="col mt-5">
            <p>
              A cookie, also known as a browser cookie or tracking cookie, is a
              small text file created by a website and stored on your device
              &#40;eg. computer&#41;.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="text-danger">What cookies do</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Cookies can't actually 'do' anything. They cannot make copies of
              themselves nor can they scan, read or modify anything on your
              device. It is technically impossible for cookies to read personal
              information. Cookies simply allow a website to recognise users
              when they return to the site. They can only ever be read or edited
              by the website that originally created them.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="text-danger">
              Why cookies are used on tico-solutions.com
            </h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              The tiflex.co.uk website uses Google Analytics, a web analytics
              service provided by Google, Inc. &#40;"Google"&#41;. Google
              Analytics uses cookies to help us analyse how our website is used.
            </p>
            <p>
              The information generated by the cookie about your use of the
              website &#40;including your IP address&#41; will be transmitted to
              and stored by Google on servers in the United States. Google will
              use this information for the purpose of evaluating your use of the
              website, compiling reports on website activity for website
              operators and providing other services relating to website
              activity and internet usage.
            </p>
            <p>
              Google may also transfer this information to third parties where
              required to do so by law, or where such third parties process the
              information on Google's behalf. Google will not associate your IP
              address with any other data held by Google.
            </p>
            <p>
              You may disable cookies by selecting the appropriate settings on
              your device &#40;see information below&#41;. By using this
              website, you consent to the processing of data about you by Google
              in the manner and for the purposes set out above.
            </p>
            <p>
              Google Analytics sets four cookies automatically when you visit
              our site. Details of each are shown below.
            </p>
            <ul>
              <li>
                <strong>Name:</strong> _utma
              </li>
              <li>
                <strong>Purpose:</strong> Contains the main details of the user
              </li>
              <li>
                <strong>Expiry date:</strong> 2 years
              </li>
            </ul>
            <ul>
              <li>
                <strong>Name:</strong> _utmb
              </li>
              <li>
                <strong>Purpose:</strong> Contains details about the current
                website visit
              </li>
              <li>
                <strong>Expiry date:</strong> 30 minutes
              </li>
            </ul>
            <ul>
              <li>
                <strong>Name:</strong> _utmc
              </li>
              <li>
                <strong>Purpose:</strong> Used only to determine if the user has
                closed their browser
              </li>
              <li>
                <strong>Expiry date:</strong> End of visit
              </li>
            </ul>
            <ul>
              <li>
                <strong>Name:</strong> _utmz
              </li>
              <li>
                <strong>Purpose:</strong> Contains referral details about the
                current visit
              </li>
              <li>
                <strong>Expiry date:</strong> 6 months
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="text-danger">Third party cookies</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              Some third party services embedded within this website, such as
              Google Maps, set their own cookies. These are known as third party
              cookies because they are not set by our website. We have no
              control over the cookies set by these services. You may disable
              third party cookies on your device &#40;see details below&#41;.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="text-danger">Website survey</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              From time to time we have a survey on the website. This uses a
              cookie to not repeat ask you to complete the same survey.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="text-danger">How to disable cookies</h6>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col">
            <p>
              For more information about cookies, including instructions on how
              to manage, remove, or disable cookies on your device, please see
              the 'All About Cookies' website at allaboutcookies.org.
            </p>
            <p>
              If you choose to disable cookies, we will not be able to gather
              valuable usage information about your visit, which helps us to
              constantly improve the website.
            </p>
            <p>
              Please note that other websites may become completely unusable.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
