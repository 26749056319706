import React from "react";
import { Card } from "antd";
import defaul from "../../images/default-tico.png"
import { Link } from "react-router-dom";
import { Button } from 'antd';

const { Meta } = Card;

const ProductCard = ({ product }) => {
  // destructure
  const { images, title, description, slug } = product;

  return (
    <Card
    style={{
      width: "auto",
    }}
    hoverable
      cover={
        <Link to={`/product/${slug}`}>
        <img
          src={images && images.length ? images[0].url : defaul}
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
          className="p-1"
          alt="product images"
        />
        </Link>
      }
      actions={[
        <Link to={`/product/${slug}`}>
        <Button type="primary" className="float-left ml-4">View Product</Button>
        </Link>,
      ]}
    >
      <Link to={`/product/${slug}`}>
      <Meta
        title={title}
        description={`${description && description.substring(0, 70)}...`}
      />
       </Link>
    </Card>
  );
};

export default ProductCard;