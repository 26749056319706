import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { getProduct } from "../functions/product";
import SingleProduct from "../components/cards/SingleProduct";
import { getRelated } from "../functions/product";
import ProductCard from "../components/cards/ProductCard";
import GeneralModal from "../components/modals/GeneralModal";
import BrochureModal from "../components/modals/BrochureModal";

const Product = ({ match }) => {
  const [product, setProduct] = useState({});
  const [related, setRelated] = useState([]);

  const { slug } = match.params;

  useEffect(() => {
    loadSingleProduct();
  }, [slug]);

  const loadSingleProduct = () => {
    getProduct(slug).then((res) => {
      setProduct(res.data);
      getRelated(res.data._id).then((res) => setRelated(res.data));
    });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mx-0 angle-product mt-3 position-absolute"></div>
      <div className="row mt-5">
        <SingleProduct product={product} />
      </div>
      {product.brochure || product.datasheet ? (
        <div className="container">
          <div className="row flex-column d-flex align-items-center">
            <div className="col text-center pt-5 pb-5">
              <h2 className="text-danger">More Information</h2>
            </div>
            <div className="row pb-5 text-center justify-content-center">
              {product.brochure && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": product.datasheet,
                    "col-md-4": !product.datasheet,
                  })}
                >
                  <BrochureModal product={product} />
                </div>
              )}
              {product.datasheet && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": product.brochure,
                    "col-md-4": !product.brochure,
                  })}
                >
                  <GeneralModal product={product} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={classNames("container-fluid", {
          "bg-white": !(product.brochure || product.datasheet),
          bgstill: product.brochure || product.datasheet,
        })}
      >
        <div className="row">
          <div className="col text-center pt-5 pb-3">
            <h3>Related Items</h3>
          </div>
        </div>
        <div className="container">
          <div className="row pb-5 justify-content-md-center">
            {related.length ? (
              related.map((r) => (
                <div key={r._id} className="col-md-3">
                  <ProductCard product={r} />
                </div>
              ))
            ) : (
              <div className="text-center col">No Items Available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
