import React from "react";
import { Link } from "react-router-dom";
import { Button, Breadcrumb } from "antd";

const Industires = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1666713720/tico-solutions-tabs/Banner-list-of-industries-tico-products-are-suitable-for_y6xp3x.jpg"
              alt="List of the most popular industries Tico supply anti-vibration and nosie isolation products to"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Industries</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Industries
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 text-danger">Solutions for Industry</h1>
            <p className="lead p-0 mt-2">
              Anti-Vibration is a consideration of every industry. Utilising
              TICO to reduce vibration will assist in a better working/living
              environment as well as reduced machinery downtime and repair
              bills. As a manufacturer of Noise and Anti-vibration solutions,
              TICO is uniquely positioned to be able to meet the varied and
              demanding requirements of every market segment across the globe.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container p-0">
          <div className="row m-0 p-0 mt-4">
            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link className="m-0" to="industries/oil-and-gas">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Oil &amp; Gas</h4>
                  <p>
                    Working in some of the harshest environments on Earth; TICO
                    products provide longevity and high performance
                    anti-vibration and pipe support solutions.
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600835/tico-solutions-tabs/solutions-tab-oil-gas_o5x1ff.png"
                    alt="Tico's most suitable materials for the structural &amp; architectual isolations"
                  />
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/engineering">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Engineering</h4>
                  <p>
                    Bespoke, high performance anti-vibration and machinery
                    mounting solutions. TICO applies noise and vibration
                    isolation science to meet modern engineering requirements.
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600621/tico-solutions-tabs/solutions-tab-engineering_whp71n.png"
                    alt="logo"
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/construction">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Construction</h4>
                  <p>
                    Ground and Structural-borne vibration isolation solutions
                    from Structural Foundations to Roof-tops. TICO can provide
                    noise and vibration isolation...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600836/tico-solutions-tabs/solutions-tab-construction_w44hcf.png"
                    alt="Tico's most suitable materials for the structural &amp; architectual isolations"
                  />
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/lift-escalator">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Lifts &amp; Escalators</h4>
                  <p>
                    Lifts and escalators provide a common source of noise and
                    vibration within a building. TICO is incorporated to reduce
                    the flanking transmission into adjacent...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600836/tico-solutions-tabs/solutions-tab-lifts-escalators_zdzvah.png"
                    alt="logo"
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/hvac">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>HVAC</h4>
                  <p>
                    Air-con units, Air filtration & Ventilation, Cooling Towers,
                    Air-source heat pumps etc are all common sights on the
                    rooftops of our towns and cities...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600835/tico-solutions-tabs/solutions-tab-hvac_eqczlz.png"
                    alt="Tico's most suitable materials for the structural &amp; architectual isolations"
                  />
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/power">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Power</h4>
                  <p>
                    From the Nuclear power plants and wind turbines that
                    generate our power, through to power distribution closer to
                    our homes - TICO provides anti-vibration...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600835/tico-solutions-tabs/solutions-tab-power_mnmyfr.png"
                    alt="logo"
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/oem">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>OEM</h4>
                  <p>
                    TICO has a long traditional of creating intricate isolation
                    solutions and our fully equipped manufacturing facility is
                    more than capable of producing...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600835/tico-solutions-tabs/solutions-tab-oem_vhtdz4.png"
                    alt="Tico's most suitable materials for the structural &amp; architectual isolations"
                  />
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-sm-12 mb-4 p-0 bgstill">
              <Link to="industries/data-centers">
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Industry</h6>
                  <h4>Data Centers</h4>
                  <p>
                    From cabinet isolation and acoustic floating floors to
                    entire isolated structures; TICO is extensively used to
                    reduce the downtime of the worlds servers.
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659600836/tico-solutions-tabs/solutions-tab-data-centers_u3shio.png"
                    alt="logo"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Industires;
