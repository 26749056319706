import React from "react";
import { Card } from "antd";
import defaul from "../../images/default-tico.png"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Meta } = Card;

const AdminSolCard = ({ sol, handleRemove }) => {

    const { title, description, images, slug } = sol;

    return (
        <Card cover={
            <img src={images && images.length ? images[0].url : defaul}
            style={{ height: "150px", objectFit: "cover"}}
            className="p-1"
            alt="Default for missing products"
             />
             }
             actions={[
                 <Link to={`/admin/sol/${slug}`}>
                 <EditOutlined className="text-warning" />
                 </Link>,
                 <DeleteOutlined onClick={() => handleRemove(slug)} className="text-danger" />,
             ]}
            >
        <Meta title={title} description={`${description && description.substring(0, 35)}...`} />
        </Card>
    );
};

export default AdminSolCard;