import React from "react";
import { Select } from "antd";

const { Option } = Select;

const ProductCreateForm = (
  {
    handleSubmit, handleChange, handleFileChange, setValues, values, handleCategoryChange, subOptions, showSub,
  }) => {
  // destruc
  const {
    title, description, categories, subs, featurea, featureb, featurec, featured,
    designa, designb, designc, designd, load, freq, min, max,
  } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={title}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Category</label>
        <select
          name="category"
          className="form-control"
          onChange={handleCategoryChange}
        >
          <option>Please select</option>
          {categories.length > 0 &&
            categories.map((c) => (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            ))}
        </select>
      </div>

      {showSub && (
        <div>
          <label>Sub Categories</label>
          <Select
            mode="multiple"
            style={{width: "100%"}}
            placeholder="Please select"
            value={subs}
            onChange={(value) => setValues({...values, subs: value})}
          >
            {subOptions.length &&
              subOptions.map((s) => (
                <Option key={s._id} value={s._id}>
                  {s.name}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <br/>
      <div className="row">
        <div className="form-group col-md-6">
          <label>Key Feature 1</label>
          <input
            type="text"
            name="featurea"
            className="form-control"
            placeholder="At least 1 key feature must be entered"
            value={featurea}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 2</label>
          <input
            type="text"
            name="featureb"
            className="form-control"
            value={featureb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 3</label>
          <input
            type="text"
            name="featurec"
            className="form-control"
            value={featurec}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 4</label>
          <input
            type="text"
            name="featured"
            className="form-control"
            value={featured}
            onChange={handleChange}
          />
        </div>
      </div>
      <br/>
      <div className="row">
        <div className="form-group col-md-6">
          <label>Design Requirement 1</label>
          <input
            type="text"
            name="designa"
            className="form-control"
            placeholder="At least 1 design requirement must be entered"
            value={designa}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 2</label>
          <input
            type="text"
            name="designb"
            className="form-control"
            value={designb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 3</label>
          <input
            type="text"
            name="designc"
            className="form-control"
            value={designc}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 4</label>
          <input
            type="text"
            name="designd"
            className="form-control"
            value={designd}
            onChange={handleChange}
          />
        </div>
      </div>
      <br/>
      <div className="row">
        <div className="form-group col-md-3">
          <label>Load Bearing</label>
          <input
            type="text"
            name="load"
            className="form-control"
            value={load}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Natural Frequency</label>
          <input
            type="text"
            name="freq"
            className="form-control"
            value={freq}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Min Temperature</label>
          <input
            type="text"
            name="min"
            className="form-control"
            value={min}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-3">
          <label>Max Temperature</label>
          <input
            type="text"
            name="max"
            className="form-control"
            value={max}
            onChange={handleChange}
          />
        </div>
      </div>
      <br/>
      <div className="row pl-3">
        <label className="btn btn-primary mr-2">
          Brochure upload
          <input
            name="brochure"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
        <label className="btn btn-primary mr-2">
          Datasheet upload
          <input
            name="datasheet"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <button className="btn btn-outline-info mt-3">Save</button>
    </form>
  );
};

export default ProductCreateForm;
