import React, { useState, useEffect } from "react";
import { getProductsByCount, getProductsByFilter } from "../functions/product";
import { getCategories } from "../functions/category";
import { useSelector } from "react-redux";
import ProductCard from "../components/cards/ProductCard";
import { Menu, Slider, Checkbox } from "antd";

const { SubMenu } = Menu;

const Search = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState([0, 16]);
  const [ok, setOk] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  let { search } = useSelector((state) => ({ ...state }));
  const { text } = search;

  useEffect(() => {
    loadAllProducts();
    getCategories().then((res) => setCategories(res.data));
  }, []);

  const fetchProducts = (arg) => {
    getProductsByFilter(arg).then((res) => {
      setProducts(res.data);
    });
  };

  // load default items
  const loadAllProducts = () => {
    getProductsByCount(36).then((p) => {
      setProducts(p.data);
      setLoading(false);
    });
  };

  // load items by search query
  useEffect(() => {
    const delayed = setTimeout(() => {
      fetchProducts({ query: text });
    }, 300);
    return () => clearTimeout(delayed);
  }, [text]);

  //load items by load bearing
  useEffect(() => {
    console.log("ok to request");
    fetchProducts({ load });
  }, [ok]);

  const handleSlider = (value) => {
    setLoad(value);
    setTimeout(() => {
      setOk(!ok);
    }, 300);
  };

  //load items by category
  const showCategories = () =>
    categories.map((c) => (
      <div key={c._id}>
        <Checkbox
          onChange={handleCheck}
          className="pb-2 pl-4 pr-4"
          value={c._id}
          name="category"
          checked={categoryIds.includes(c._id)}
        >
          {c.name}
        </Checkbox>
        <br />
      </div>
    ));

  //check for categories
  const handleCheck = (e) => {
    //console.log(e.target.value);
    let inTheState = [...categoryIds];
    let justChecked = e.target.value;
    let foundInTheState = inTheState.indexOf(justChecked);

    if (foundInTheState === -1) {
      inTheState.push(justChecked);
    } else {
      inTheState.splice(foundInTheState, 1);
    }
    setCategoryIds(inTheState);
    //console.log(inTheState);
    fetchProducts({ category: inTheState });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mt-5 mb-5">Loading...</h4>
            ) : (
              <h1 className="text-danger mt-n5 mb-5">
                Search by Product Parameters
              </h1>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 pt-2">
            <hr />
            <Menu defaultOpenKeys={["1", "2"]} mode="inline">
              <SubMenu
                key="1"
                title={
                  <span>
                    <p>
                      Optimum Load Bearing (MN/m<sup>2</sup>)
                    </p>
                  </span>
                }
              >
                <div>
                  <Slider
                    className="ml-4 mr-4"
                    tipFormatter={(v) => v}
                    range
                    value={load}
                    onChange={handleSlider}
                    max="16"
                  />
                </div>
              </SubMenu>

              <SubMenu
                key="2"
                title={
                  <span>
                    <p>Categories</p>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showCategories()}</div>
              </SubMenu>
            </Menu>
          </div>

          <div className="col-md-9 pt-2">
            {loading ? (
              <h4 className="text-danger">Loading...</h4>
            ) : (
              <h4 className="text-danger">Search Results</h4>
            )}

            {products.length < 1 && <p>No items found</p>}

            <div className="row pb-5">
              {products.map((p) => (
                <div key={p._id} className="col-md-4 mt-3">
                  <ProductCard product={p} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
