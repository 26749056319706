import {Button, Modal} from 'antd';
import React, {useState} from 'react';

const ThreeModal = ({ title, sketchfab, mp4File }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button className="mt-4" size="large" type="primary" onClick={() => setVisible(true)}>
        View Video / Interactive Model
      </Button>
      <Modal
        title={title}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        {sketchfab ? (
          <div className="sketchfab-embed-wrapper">
            <iframe
              width="100%"
              height="500px"
              title="Solution Sketchfab File"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; fullscreen; xr-spatial-tracking"
              src={sketchfab}>
            </iframe>
          </div>
        ) : null}
        {mp4File ? (
          <video width="100%" autoPlay controls>
            <source src={mp4File.url} type="video/mp4" />
                Your browser does not support HTML video.
          </video>
        ) : null}
      </Modal>
    </>
  );
};

export default ThreeModal;
