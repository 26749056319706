import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.less";
// import ReactGA from "react-ga";

import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import HamburgerMenu from "./components/nav/HamburgerMenu";
import ScrollToTop from "./components/nav/ScrollToTop";

import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import NavBar from "./components/nav/NavBar";
import Footer from "./components/home/Footer";
import RegisterComplete from "./pages/auth/RegisterComplete";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AdminRoute from "./components/routes/AdminRoute";
import AdminDashboard from "./pages/admin/AdminDashboard";
import CategoryCreate from "./pages/admin/category/CategoryCreate";
import SolutionCreate from "./pages/admin/solution/SolutionCreate";
import CategoryUpdate from "./pages/admin/category/CategoryUpdate";
import SolutionUpdate from "./pages/admin/solution/SolutionUpdate";
import SubCreate from "./pages/admin/sub/SubCreate";
import SubsolCreate from "./pages/admin/subsol/SubsolCreate";
import SubUpdate from "./pages/admin/sub/SubUpdate";
import SubsolUpdate from "./pages/admin/subsol/SubsolUpdate";
import SolCreate from "./pages/admin/sol/SolCreate";
import ProductCreate from "./pages/admin/product/ProductCreate";
import AllProducts from "./pages/admin/product/AllProducts";
import AllSols from "./pages/admin/sol/AllSols";
import ProductUpdate from "./pages/admin/product/ProductUpdate";
import SolUpdate from "./pages/admin/sol/SolUpdate";
import Product from "./pages/Product";
import Solution from "./pages/Solution";
import Solutions from "./pages/Solutions";
import SolutionHome from "./pages/solution/SolutionHome";
import CategoryHome from "./pages/category/CategoryHome";
import SubHome from "./pages/sub/SubHome";
import ProductApplication from "./pages/ProductApplication";
import ProductParameters from "./pages/ProductParameters";
import Search from "./pages/Search";
import Industries from "./pages/Industries";
import OilGas from "./pages/OilGas";
import Engineering from "./pages/Engineering";
import Construction from "./pages/Construction";
import LiftEscalator from "./pages/LiftEscalator";
import Hvac from "./pages/Hvac";
import Power from "./pages/Power";
import Oem from "./pages/Oem";
import DataCenter from "./pages/DataCenter";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Distributors from "./pages/Distributors";
import RecentProject from "./pages/RecentProject";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CookiePolicy from "./pages/CookiePolicy";
import Thankyou from "./pages/ThankYou";

const App = () => {
  const dispatch = useDispatch();

  // const TRACKING_ID = "G-RMBP9E1Z6Q"; // YOUR_OWN_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        console.log("user", user);
        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <>
      <NavBar />
      <HamburgerMenu />
      <div>
        <ToastContainer />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/register/complete" component={RegisterComplete} />
          <Route exact path="/forgot/password" component={ForgotPassword} />
          <AdminRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          <AdminRoute exact path="/admin/category" component={CategoryCreate} />
          <AdminRoute exact path="/admin/solution" component={SolutionCreate} />
          <AdminRoute exact path="/admin/sol" component={SolCreate} />
          <AdminRoute
            exact
            path="/admin/category/:slug"
            component={CategoryUpdate}
          />
          <AdminRoute
            exact
            path="/admin/solution/:slug"
            component={SolutionUpdate}
          />
          <AdminRoute exact path="/admin/sub" component={SubCreate} />
          <AdminRoute
            exact
            path="/admin/sub-solution"
            component={SubsolCreate}
          />
          <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
          <AdminRoute
            exact
            path="/admin/sub-solution/:slug"
            component={SubsolUpdate}
          />
          <AdminRoute exact path="/admin/product" component={ProductCreate} />
          <AdminRoute exact path="/admin/products" component={AllProducts} />
          <AdminRoute exact path="/admin/sols" component={AllSols} />
          <AdminRoute
            exact
            path="/admin/product/:slug"
            component={ProductUpdate}
          />
          <AdminRoute exact path="/admin/sol/:slug" component={SolUpdate} />
          <Route exact path="/product/:slug" component={Product} />
          <Route exact path="/sol/:slug" component={Solution} />
          <Route exact path="/category/:slug" component={CategoryHome} />
          <Route exact path="/solution/:slug" component={SolutionHome} />
          <Route exact path="/sub/:slug" component={SubHome} />
          <Route
            exact
            path="/product-parameters"
            component={ProductParameters}
          />
          <Route
            exact
            path="/product-application"
            component={ProductApplication}
          />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/industries" component={Industries} />
          <Route exact path="/industries/oil-and-gas" component={OilGas} />
          <Route exact path="/industries/engineering" component={Engineering} />
          <Route
            exact
            path="/industries/construction"
            component={Construction}
          />
          <Route
            exact
            path="/industries/lift-escalator"
            component={LiftEscalator}
          />
          <Route exact path="/industries/hvac" component={Hvac} />
          <Route exact path="/industries/power" component={Power} />
          <Route exact path="/industries/oem" component={Oem} />
          <Route exact path="/industries/data-centers" component={DataCenter} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/distributors" component={Distributors} />
          <Route exact path="/recent-project" component={RecentProject} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/thank-you" component={Thankyou} />
        </Switch>
        <ScrollToTop />
        {/* <RouteChangeTracker /> */}
      </div>
      <Footer />
    </>
  );
};

export default App;
