import React, { useEffect, useRef } from "react";
import HomepageModal from "../components/modals/HomepageModal";
import { Button } from "antd";
import struct from "../images/Structural-Architectural-solutions-tab.png";
import plant from "../images/Plant-Machinery-Isolation-soultions-tab.png";
import pipe from "../images/Pipe-Systems-solutions-tab.png";
import thermal from "../images/Thermal-break-solutions-tab.png";
import {
  ExperimentOutlined,
  AreaChartOutlined,
  TeamOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import YoutubeEmbed from "../components/videos/YoutubeEmbed";
import { Link } from "react-router-dom";

const Home = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle">
          <div className="container p-0">
            <div className="row mt-5">
              <div className="col-lg-5 col-md-12 angle-ret mt-5 p-0">
                <h1 className="mt-5 pt-4 h1-pad">
                  Isolation Solutions for a quieter world
                </h1>
                <p className="lead white p-pad">
                  Specialised UK Manufacturer of noise and vibration isolation
                  solutions for buildings and machinery
                </p>
              </div>
              <div className="col-lg-7 col-md-12 mt-5 angle-ret mb-1 text-center">
                <video
                  style={{
                    maxWidth: "100%",
                    width: "800px",
                    margin: "0 auto",
                  }}
                  playsInline
                  loop
                  muted
                  controls
                  alt="Discover what Tico can do for isolating vibration solutions"
                  src="https://res.cloudinary.com/dkfjd6cdn/video/upload/v1670349423/tico-solution-video/explore-Tico-interactive-model_wzqhr4.mp4"
                  ref={videoEl}
                />
                <p className="text-center text-white ml-2 mb-5">
                  Click button below to view the TICO Interactive model
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container negative p-0">
          <div className="row">
            <div className="col-lg-5"></div>
            <div className="col-lg-7 text-center">
              <HomepageModal />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="container p-0">
          <div className="row m-0 p-0 mt-4">
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <Link
                to={{
                  pathname: "solutions",
                  state: { solution: "structural-and-architectural" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Solutions</h6>
                  <h4>Structural &amp; Architectual</h4>
                  <p>
                    Providing structural vibration isolation solutions from
                    groundbourne foundation isolation through to floating gym
                    floors and room-in-room isolation...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={struct}
                    alt="Tico's most suitable materials for the structural &amp; architectual isolations"
                  />
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <Link
                to={{
                  pathname: "solutions",
                  state: { solution: "plant-and-machinery-isolation" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Solutions</h6>
                  <h4>Plant &amp; Machinery Isolation</h4>
                  <p>
                    Vibration isolation enables plant and machinery to work at
                    its designed optimum efficency, reduces downtime and
                    maintenance whilst improving...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={plant}
                    alt="compressor in situ with TICO materials isolating noise &amp; vibration"
                  />
                </div>
              </Link>
            </div>
          </div>

          <div className="row m-0 p-0">
            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <Link
                to={{
                  pathname: "solutions",
                  state: { solution: "pipe-systems" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Solutions</h6>
                  <h4>Pipe Systems</h4>
                  <p>
                    TICO Pipe Support Solutions provide an effective method of
                    adding support into any pipework structure, reducing noise,
                    vibration and preventing corrosion...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mb-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img
                    src={pipe}
                    alt="Tico pipe suppports attached to a large industrial pipe system"
                  />
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-sm-12 mb-4 p-0 bgstill">
              <Link
                to={{
                  pathname: "solutions",
                  state: { solution: "thermal-breaks" },
                }}
              >
                <div className="hp-sol pl-4">
                  <h6 className="mt-4">Solutions</h6>
                  <h4>Thermal Breaks</h4>
                  <p>
                    High performance thermal insulators used between both
                    horizontal and vertical connections of internal and external
                    elements to prevent thermal/cold bridging...
                  </p>
                  <Button
                    type="primary"
                    size="large"
                    className="float-left mt-3 mb-3 ml-3"
                  >
                    More Info
                  </Button>
                </div>
                <div className="hp-sol hover ml-3">
                  <img src={thermal} alt="logo" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="container-fluid bgstill p-0">
          <h2 className="text-center bgstill pt-5 pb-5 mt-5 mb-0 text-danger">
            About Us
          </h2>
          <div className="container">
            <div className="row bgstill text-center justify-content-center">
              <div className="col-md-4">
                <h6 className="pt-5">OVER 70 YEARS EXPERIENCE</h6>
                <br />
                <TeamOutlined
                  className="text-danger"
                  style={{ fontSize: "800%" }}
                />
                <p className="pt-5 pb-3">
                  TICO has been at the forefront of Isolation Solutions for over
                  70 years. This experience supported by a skilled workforce of
                  over 150 people, can be heavily relied upon to deliver for
                  every project.
                </p>
              </div>
              <div className="col-md-4">
                <h6 className="pt-5">ENGINEERING EXCELLENCE</h6>
                <br />
                <AreaChartOutlined
                  className="text-danger"
                  style={{ fontSize: "800%" }}
                />
                <p className="pt-5 pb-3">
                  Working closely with our customers, our polymer technologists
                  work to develop solutions to a variety of engineering problems
                  particularly those requiring high performance and product
                  durability.
                </p>
              </div>
              <div className="col-md-4">
                <h6 className="pt-5">RESEARCH &amp; DEVELOPMENT</h6>
                <br />
                <ExperimentOutlined
                  className="text-danger"
                  style={{ fontSize: "800%" }}
                />
                <p className="pt-5 pb-3">
                  Tiflex has a state of the art technological laboratory,
                  providing us with the facilities to conduct all of our own
                  testing.
                </p>
              </div>
            </div>

            <div className="row bgstill p-5 mt-0 mb-0 justify-content-center">
              <Link to="about-us">
                <Button type="primary" size="large">
                  Learn More About Us
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="container mt-5 mb-5 p-0">
          <div className="row pt-5 pb-5 align-items-center">
            <div className="col-lg-7 mt-5 justify-content-center">
              <YoutubeEmbed embedId="GCVWXNCQpWY" />
            </div>
            <div className="col-lg-5 pr-5">
              <div className="col mt-5 mb-5">
                <h3 className="pl-5">TICO Project</h3>
                <p className="mb-3 pl-5 pt-2 lead">
                  The Botanic Link is a highly technical and complex project
                  creating 253 student bed spaces, the structure was built...
                </p>
                <Link to="recent-project">
                  <Button type="primary" size="large" className="ml-5 mt-2">
                    Read More...
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div className="container-fluid p-0">
          <div className="col back">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center mt-5 pt-5 pt-3">
                  {" "}
                  <CommentOutlined
                    className="text-white"
                    style={{ fontSize: "1200%" }}
                  />
                </div>
                <div className="col-md-8 mt-5 pt-5 pb-5">
                  <h3 className="text-white">Want to know more about TICO.</h3>
                  <p className="text-white lead">
                    All of our products are supported by full commercial and
                    technical literature and supported by a technical team to
                    provide you with pre-contract and on-site assistance.
                  </p>
                  <Link to="contact-us">
                    <Button type="primary" size="large" className="mt-2">
                      Contact Us
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default Home;
