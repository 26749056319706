import axios from "axios";

export const getSolutions = async () =>
  await axios.get(`${process.env.REACT_APP_API}/solutions`);

export const getSolution = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/solution/${slug}`);

export const removeSolution = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/solution/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const updateSolution = async (slug, solution, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/solution/${slug}`, solution, {
    headers: {
      authtoken,
    },
  });

export const createSolution = async (solution, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/solution`, solution, {
    headers: {
      authtoken,
    },
  });

export const getSolutionSubsols = async (_id) =>
  await axios.get(`${process.env.REACT_APP_API}/solution/sub-solutions/${_id}`);
