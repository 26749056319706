import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import Search from "../forms/Search";
import ticoLogoReg from "../../images/Tico-Logo_registered.svg";

const { SubMenu, Item } = Menu;

const NavBar = () => {
  const [current, setCurrent] = useState("home");
  let dispatch = useDispatch();
  let { user } = useSelector((state) => ({ ...state }));
  let history = useHistory();

  const handleClick = (e) => setCurrent(e.key);

  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    history.push("/login");
  };

  return (
    <div className="nav-bar">
      <div
        style={{
          float: "left",
          width: "120px",
          height: "80px",
          padding: "22px",
          background: "rgba(0, 0, 0, 1)",
        }}
      >
        <Link to="/">
          <img src={ticoLogoReg} alt="Tico company logo" />
        </Link>
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        className="d-flex justify-content-end"
      >
        <Item key="home">
          <Link to="/">HOME</Link>
        </Item>
        <Item key="solutions">
          <Link to="/solutions">SOLUTIONS</Link>
        </Item>
        <SubMenu key="SubMenu2" title="PRODUCTS">
          <Item key="product-parameters">
            <Link to="/product-parameters">Filter by Product Parameters</Link>
          </Item>
          <Item key="product-application">
            <Link to="/product-application">Filter by Product Application</Link>
          </Item>
        </SubMenu>
        <Item key="industries">
          <Link to="/industries">INDUSTRIES</Link>
        </Item>
        <Item key="services">
          <Link to="/services">SERVICES</Link>
        </Item>
        <SubMenu key="SubMenu4" title="CONTACT US">
          <Item key="contact-us">
            <Link to="/contact-us">Contact Us</Link>
          </Item>
          <Item key="distributors">
            <Link to="/distributors">Distributors</Link>
          </Item>
        </SubMenu>
        <Item key="about-us">
          <Link to="/about-us">ABOUT US</Link>
        </Item>
        <Item key="Buy Online">
          <a href="https://tico-online.com/" target="_blank" rel="noreferrer">
            BUY ONLINE
          </a>
        </Item>
        {user && (
          <SubMenu key="SubMenu5" title="Account">
            {user && user.role === "Administrator" && (
              <Item key="admin-menu">
                <Link to="/admin/sols">Admin Menu</Link>
              </Item>
            )}
            <Item key="register">
              <Link to="/register">reg</Link>
            </Item>
            <Item key="login">
              <Link to="/login">login</Link>
            </Item>
            <Item key="logout" onClick={logout}>
              logout
            </Item>
          </SubMenu>
        )}
        {/* <span className="float-right p-1"> */}
        {/* <Search /> */}
        {/* </span> */}
      </Menu>
    </div>
  );
};

export default NavBar;
