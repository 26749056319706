import { Card, Button, Breadcrumb } from "antd";
import React from "react";
import YoutubeEmbed from "../components/videos/YoutubeEmbed";
import { Link } from "react-router-dom";

const imgStyle = {
  width: "100%",
  height: "auto",
  lineHeight: "160px",
  textAlign: "center",
};

const { Meta } = Card;

const OilGas = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669117120/tico-solutions-tabs/industrial-oil-gas-header_a0ztew.jpg"
              alt="Off-shore working oil/gas rig"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Oil &amp; Gas</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/industries">
                <span>Industries</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Oil &amp; Gas
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-danger">
              Products for the Oil &amp; Gas Industry
            </h1>
            <p className="lead p-0 mt-2">
              TICO has served the Oil &amp; Gas Industry for more than 60 years.
              Our range of products are used for upstream and downstream and are
              commonly specified on a number of projects including most recently
              2 projects, namely the Hebron and Shah Deniz.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center mb-5 pb-5">
          <div className="col-12 mt-5">
            <h3 className="text-center">Typical Applications</h3>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659614557/tico-solutions-tabs/Onshore-applications-for-oil-and-gas_ddzh90.png"
                alt="TICO products for the oil and gas industry suitable for onshore applications"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659614557/tico-solutions-tabs/Offshore-applications-for-oil-and-gas_etx5un.png"
                alt="TICO products for the oil and gas industry suitable for offshore applications"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659614557/tico-solutions-tabs/Ship-building-for-oil-and-gas_gxpstm.png"
                alt="TICO products for the oil and gas industry suitable for ship building"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659614558/tico-solutions-tabs/Heavy-industry-for-oil-and-gas_jqgfu2.png"
                alt="TICO products for the oil and gas industry suitable for bespoke applications"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659614558/tico-solutions-tabs/Bespoke-applications-for-oil-and-gas_opjitn.png"
                alt="TICO products for the oil and gas industry suitable for bespoke applications"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 pt-5 pb-4">
            <p className="lead text-center p-5 mt-5 mb-5">
              TICO is designed to help eliminate pipework fatigue caused by
              excessive vibrations and is also used to reduce Vibration and
              Noise transmission from pumps, generators and compressors within
              Refineries and Petrochemical plants. Our products are backed with
              full support from our Technical Support Team who are able to
              advise on the best solutions to any vibration problem specific to
              the Oil &amp; Gas Industry.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-8 mt-5 pt-5 mb-5 pb-5">
            <YoutubeEmbed embedId="qR0MXwD4jtw" />
          </div>
        </div>
      </div>

      <div className="container">
        {/* row 1 of products */}
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Suitable Products</h3>
            <p className="lead">
              We have developed a range of anti-vibration, high temperature and
              fire retardant Pipe Support products for use within the Oil &amp;
              Gas industry including Pipe Supports, Clamp Lining Clip Strips and
              Sliding Bearings.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico-pipe-support-clamp-grip-type"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851237/sk5mjrrlv41ayrtdvktb.jpg"
                />
              }
              actions={[
                <Link to={`/sol/insulated-u-bolt-pipe-support-grip`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`sol/insulated-u-bolt-pipe-support-grip`}>
                <Meta
                  title="Pipe Support Clamp - Grip Type"
                  description="Configuration enables the pipe to be gripped and supported while accommodating small axial and torsional movements..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Pipe Support Clamp - Guide Type"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663850991/d2m8s5hkexsacfuoypur.jpg"
                />
              }
              actions={[
                <Link to={`/sol/insulated-u-bolt-pipe-support-guide`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/insulated-u-bolt-pipe-support-guide`}>
                <Meta
                  title="Pipe Support Clamp - Guide Type"
                  description="Specifically designed to minimise vibration transmission between pipework and hanger, and also preventing corrosion..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Pipe Support Clamp - VHT Type"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663850815/sidqgw88se0cq1uhg2du.jpg"
                />
              }
              actions={[
                <Link to={`/sol/insulated-u-bolt-pipe-support-vht`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/insulated-u-bolt-pipe-support-vht`}>
                <Meta
                  title="Pipe Support Clamp - VHT Type"
                  description="Very high temperature support is ideally suited for applications where temperatures range from &#45;60°C to &#43;300°C..."
                />
              </Link>
            </Card>
          </div>
        </div>
        {/* row 2 of products */}
        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Pipe Support Clamp Liners"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663847789/bbtjloddl6yei0y0vfev.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-strip-liner-scl`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-strip-liner-scl`}>
                <Meta
                  title="Pipe Clamp Strip Liner - S/CL"
                  description="Manufactured from our well known TICO S rubber bonded cork material and is red/brown in colour..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="mid temperature pipe clamp strip material"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663847649/t7jh7u8fiaxiow0gk6ox.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-strip-liner-htcl`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-strip-liner-htcl`}>
                <Meta
                  title="Pipe Clamp Strip Liner - HT/CL"
                  description="Rubber bonded cork material engineered to accommodate higher operating temperatures and is green in colour..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="high temperature pipe clamp material"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663847215/xmtxios8cz7dlrod8vep.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-strip-liner-vhtcl`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-strip-liner-vhtcl`}>
                <Meta
                  title="Pipe Clamp Liners - VHT/CL"
                  description="Fire retardant pipe clamp liner is an extruded polymer with a wide operating temperature range of &#45;50°C to &#43;300°C..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 3 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="clamp block - single pipe system"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663846473/se6q6kcmvjn6s8vmp4u3.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-clamp-block-single`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-clamp-block-single`}>
                <Meta
                  title="Pipe Clamp Block - Single"
                  description="TICO Clamp blocks absorb shock and vibration caused by fluid velocity and turbulence in pipes..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="clamp block - multi pipe system"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663846386/veph0enwly8xqjsczxpy.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-clamp-block-multi`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-clamp-block-multi`}>
                <Meta
                  title="Pipe Clamp Block - Multi"
                  description="TICO Clamp blocks absorb shock and vibration caused by fluid velocity and turbulence in pipes..."
                />
              </Link>
            </Card>
          </div>
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico slide or movement bearings"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664532715/ppmxaaluq9csy2fcp3ly.jpg"
                />
              }
              actions={[
                <Link to={`/sol/slide-bearing-assemblies`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/slide-bearing-assemblies`}>
                <Meta
                  title="Slide Bearings"
                  description="Designed to accommodate large allowable movements within a wide range of applications..."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default OilGas;
