import { Image, Button, Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1666713720/tico-solutions-tabs/Banner-full-list-of-tico-services-for-any-project_i8vjfv.jpg"
              alt="TICO anti-vibration and noise isolation and the many services we supply to help make a quiter working environment"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Services</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Services
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 text-danger">TICO Services</h1>
            <p className="lead p-0 mt-2  mb-5">
              Tiflex are committed to a programme of continuous improvement, not
              only for our staff, but also for our materials and the production
              processes involved in the manufacture of the products. One of the
              key components of the Tiflex Business strategy is ongoing research
              and development; we are committed to developing new and innovative
              products to meet the ever-evolving demands and requirements of
              industry.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3 p-0"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1670940969/tico-solutions-tabs/about-water-jet-cutter_kp2suc.png"
              alt="water jet cutter in Tico factory"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid mt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1670940969/tico-solutions-tabs/about-routers-in-factory_gbraj1.png"
              alt="router tables inside Tiflex factory"
            />
          </div>
        </div>
        <div className="row">
          <div className="col mt-5 p-0">
            <h2 className="mt-5 text-danger">
              Bespoke Solutions &amp; Formulations
            </h2>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col mb-5 p-0">
            <p className="lead">
              As a manufacturer, Tiflex is able to create bespoke formulations
              to enable our products to meet the most stringent of performance
              requirements. We have the capability to produce what you want
              rather than what we think you need. We continually invest in the
              latest manufacturing capabilities to ensure we meet the increasing
              and diverse needs of modern industry.
            </p>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container mt-5">
          <div className="col-12 pt-5 pb-4">
            <h2 className=" text-danger text-center mt-5">Design Services</h2>
            <p className="lead text-center pl-5 pr-5">
              As a manufacturer, Tiflex is able to offer a diverse and
              personalised product design service. Such unique and bespoke
              capabilities are essential in industries such as Defence and
              construction where very few projects are the same.
            </p>
            <p className="lead mb-5 text-center pl-5 pr-5 pb-5">
              Tiflex can work with you from initial product brief right through
              to manufacturing. Our Research and Development teams have years of
              experience enabling us to meet and exceed your expectations.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col mt-5 p-0">
            <h2 className="text-danger mt-5 pb-2">Capabilites</h2>
            <h4>Testing</h4>
            <p className="pt-1 lead">
              Tiflex has, on site in Liskeard, a state of the art laboratory,
              providing us with the facilities to conduct our own in-house
              testing. Our products are sold into a diverse range of industries
              and regions of the world. They are required to meet many different
              industry and country standards. Tiflex pride themselves on the
              high quality of their products and their ability to meet and
              exceed the performance of competitors' products in the key
              benefits offered by the materials.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col mt-3 p-0">
            <Image
              className="img-fluid"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1660040731/tico-solutions-tabs/services-testing-load-bearing-properties-of-tico-material_x0sall.png"
              alt="Laddish bearings designed for large scale forging hammers"
            />
          </div>
        </div>
        <div className="row">
          <div className="col mt-4 p-0">
            <p className="mb-5 lead">
              Products are manufactured and tested to ensure that they can
              withstand even the most strenuous of conditions under which
              competitors materials fail. Part of the laboratory is dedicated to
              evaluating static, dynamic and impact performance, in addition to
              the general testing regimes to evaluate materials and processes in
              line with our company quality systems.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col p-0 mb-5">
            <h4>Manufacturing</h4>
            <p className="pt-1 lead">
              We undertake high volume production as well as the manufacture of
              bespoke items, many of which are designed in-house on behalf of
              our customers. Work is carried out on major projects throughout
              the world and almost half of our production is sold overseas.
              Working closely with our customers, our polymer technologists work
              to develop solutions to a variety of engineering problems
              particularly those requiring high performance and product
              durability.
            </p>
            <p className="pt-1 lead">
              All Tiflex products are manufactured in a BSI registered factory
              operating a Quality Management System which complies with the
              requirements of BS EN ISO 9001:2015.
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-5 bgstill">
        <div className="container mt-5">
          <div className="col mt-5">
            <h2 className=" text-danger text-center mt-5 pt-4">
              Technical Assistance &amp; CPD's
            </h2>
            <p className="lead pl-5 pr-5 text-center">
              All of our products are supported by full commercial and technical
              literature and supported by a technical team to provide you with
              pre-contract and on-site assistance. Our experienced team will
              work with you to ensure the correct selection of product and will,
              where necessary, provide advice on design.
            </p>
            <p className="lead pl-5 pr-5 text-center mb-5">
              We are able to provide CPD / Lunch &amp; Learn sessions to various
              technical levels to give more insight into our services,
              capabilities and competencies. Sessions can be tailored to provide
              maximum benefit to the recipients. Please contact us to check
              availability.
            </p>
          </div>
          <div className="col text-center mb-5">
            <Link to="contact-us">
              <Button type="primary" size="large" className="mb-5">
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
