import React from "react";
import {
  PhoneOutlined,
  MailOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const Contact = () => {
  return (
    <>
      <div className="container-fluid p-0 mt-5">
        <div className="row mx-0 angle-product mt-5 position-absolute"></div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-danger mb-3">Contact Us</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-5 bg">
              <p className="pt-5 pl-3 pr-3 lead">
                Any questions or technical issues, our friendly team is here to
                help.
              </p>
              <h6 className="text-danger pl-3 pr-4">Company Address</h6>
              <ul className="no-bullets pl-3 pr-4">
                <li>Tiflex House, Doublebois, Liskeard,</li>
                <li> Cornwall. PL14 4NB UK</li>
                <li className="pt-3">
                  <ClockCircleOutlined
                    style={{ fontSize: "20px", float: "left" }}
                    className="text-danger pr-2"
                  />
                  Monday - Thursday 8:30am to 5pm,
                </li>
                <li>
                  <ClockCircleOutlined
                    style={{ fontSize: "20px", float: "left" }}
                    className="text-danger pr-2"
                  />
                  Friday 8:30am to 2pm
                </li>
              </ul>
              <h6 className="text-danger pt-4 pl-3 pr-4">Other ways</h6>
              <ul className="no-bullets">
                <li className="pt-1 pl-3 pr-4">
                  <PhoneOutlined
                    style={{ fontSize: "20px", float: "left" }}
                    className="text-danger pr-2"
                  />
                  <a className="black" href="tel:+441579320808">
                    +44 &#40;0&#41; 1579 320808
                  </a>
                </li>
                <li className="pt-3 pl-3 pr-4 pb-5">
                  <MailOutlined
                    style={{ fontSize: "20px", float: "left" }}
                    className="text-danger pr-2"
                  />
                  <a className="black" href="mailto:tico@tico-solutions.com">
                    tico@tico-solutions.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <form
                className="mt-5"
                target="_blank"
                action="https://formsubmit.co/aomahony@tiflex.co.uk"
                method="POST"
              >
                <div class="form-group">
                  <div class="form-row">
                    <div class="col">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        placeholder="Full Name"
                        required
                      />
                    </div>
                    <div class="col">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Email Address"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-row">
                    <div class="col">
                      <input
                        type="text"
                        name="company"
                        class="form-control"
                        placeholder="Company"
                      />
                    </div>
                    <div class="col">
                      <input
                        type="tel"
                        name="telephone"
                        class="form-control"
                        placeholder="Telephone number"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <textarea
                    placeholder="Your Message"
                    class="form-control"
                    name="message"
                    rows="7"
                    required
                  ></textarea>
                </div>
                <button type="submit primary" class="btn btn-lg btn-dark">
                  Submit Form
                </button>
              </form>
            </div>
          </div>

          <div className="row pt-5">
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1xJzl0ktY9xUJ4UK65BE9JqL4Pyz2MvU&ehbc=2E312F"
              title="Map of the Tiflex Factory in Cornwall"
              width="100%"
              height="480"
            ></iframe>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
