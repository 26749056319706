import { Button, Modal } from "antd";
import React, { useState } from "react";

const HomepageModal = ({ title }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        className="mb-4 btn-edit"
        size="large"
        type="danger"
        onClick={() => setVisible(true)}
      >
        VIEW INTERACTIVE 3D MODEL
      </Button>
      <Modal
        title="Discover all TICO products through our Interactive Model"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1200}
      >
        <div class="sketchfab-embed-wrapper">
                {" "}
                <iframe
                  title="TICO isolation products in situ"
                  width="100%"
            height="600px"
                  frameborder="0"
                  allowfullscreen
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                  allow="autoplay; fullscreen; xr-spatial-tracking"
                  xr-spatial-tracking
                  execution-while-out-of-viewport
                  execution-while-not-rendered
                  web-share
                  src="https://sketchfab.com/models/d7cd59adfa6040ce8911226303c455ba/embed?autostart=1"
                >
                  {" "}
                </iframe>{" "}
              </div>
      </Modal>
    </>
  );
};

export default HomepageModal;
