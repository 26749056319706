import React, { useState, useEffect } from "react";
import AdminNav from "../../../components/nav/AdminNav";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getSol, updateSol } from "../../../functions/sol";
import { getSolutions, getSolutionSubsols } from "../../../functions/solution";
import FileUpload from "../../../components/forms/FileUpload";
import { LoadingOutlined } from "@ant-design/icons";
import SolUpdateForm from "../../../components/forms/SolUpdateForm";

const initialState = {
  title: "",
  description: "",
  solution: "",
  subsols: [],
  images: [],
  featurea: "",
  featureb: "",
  featurec: "",
  featured: "",
  designa: "",
  designb: "",
  designc: "",
  designd: "",
};

const SolUpdate = ({ match, history }) => {
  const [values, setValues] = useState(initialState);
  const [solutions, setSolutions] = useState([]);
  const [subsolOptions, setSubsolOptions] = useState([]);
  const [arrayOfSubsols, setArrayOfSubsols] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => ({ ...state }));
  // router
  const { slug } = match.params;

  useEffect(() => {
    loadSol();
    loadSolutions();
  }, []);

  const loadSol = () => {
    getSol(slug).then((l) => {
      console.log("single sol", l);
      setValues({ ...values, ...l.data });
      getSolutionSubsols(l.data.solution._id).then((res) => {
        setSubsolOptions(res.data);
      });
      let arr = [];
      l.data.subsols.forEach((b) => {
        arr.push(b._id);
      });
      setArrayOfSubsols((prev) => arr);
    });
  };

  const loadSolutions = () =>
    getSolutions().then((u) => {
      console.log("Get Solutions for updated sol", u.data);
      setSolutions(u.data);
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    values.subsols = arrayOfSubsols;
    values.solution = selectedSolution ? selectedSolution : values.solution;

    updateSol(slug, values, user.token)
      .then((res) => {
        setLoading(false);
        toast.success(`"${res.data.title}" is updated`);
        history.push("/admin/sols");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSolutionChange = (e) => {
    e.preventDefault();
    console.log("CLICKED SOLUTION", e.target.value);
    setValues({ ...values, subsols: [], solution: e.target.value });
    getSolutionSubsols(e.target.value).then((res) => {
      console.log("SUBSOL OPTIONS ON SOLUTION CLICK", res);
      setSubsolOptions(res.data);
    });
  };

  return (
    <div className="container-fluid p-0 mt-5">
      <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-danger mb-3">Admin</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-2">
            <AdminNav />
          </div>

          <div className="col-md-10">
            {loading ? (
              <LoadingOutlined className="text-danger h1" />
            ) : (
              <h4>Update Solution</h4>
            )}

            <div className="p-3">
              <FileUpload
                values={values}
                setValues={setValues}
                setLoading={setLoading}
              />
            </div>

            <SolUpdateForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              setValues={setValues}
              values={values}
              handleSolutionChange={handleSolutionChange}
              solutions={solutions}
              subsolOptions={subsolOptions}
              arrayOfSubsols={arrayOfSubsols}
              setArrayOfSubsols={setArrayOfSubsols}
              selectedSolution={selectedSolution}
            />
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolUpdate;
