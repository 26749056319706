import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SolUpdateForm = ({
  handleSubmit,
  handleChange,
  setValues,
  values,
  handleSolutionChange,
  solutions,
  subsolOptions,
  arrayOfSubsols,
  setArrayOfSubsols,
  selectedSolution,
}) => {
  // destructure
  const {
    title,
    description,
    solution,
    featurea,
    featureb,
    featurec,
    featured,
    designa,
    designb,
    designc,
    designd,
  } = values;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={title}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label>Description</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={description}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label>Solution</label>
        <select
          name="solution"
          className="form-control"
          onChange={handleSolutionChange}
        >
          <option>{solution ? solution.name : "Please select"}</option>
          {solutions.length > 0 &&
            solutions.map((u) => (
              <option key={u._id} value={u._id}>
                {u.name}
              </option>
            ))}
        </select>
      </div>

      <div>
        <label>Sub Solutions</label>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Please select"
          value={arrayOfSubsols}
          onChange={(value) => setArrayOfSubsols(value)}
        >
          {subsolOptions.length &&
            subsolOptions.map((b) => (
              <Option key={b._id} value={b._id}>
                {b.name}
              </Option>
            ))}
        </Select>
      </div>

      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Key Feature 1</label>
          <input
            type="text"
            name="featurea"
            className="form-control"
            placeholder="At least 1 key feature must be entered"
            value={featurea}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 2</label>
          <input
            type="text"
            name="featureb"
            className="form-control"
            value={featureb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 3</label>
          <input
            type="text"
            name="featurec"
            className="form-control"
            value={featurec}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 4</label>
          <input
            type="text"
            name="featured"
            className="form-control"
            value={featured}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Design Requirement 1</label>
          <input
            type="text"
            name="designa"
            className="form-control"
            placeholder="At least 1 design requirement must be entered"
            value={designa}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 2</label>
          <input
            type="text"
            name="designb"
            className="form-control"
            value={designb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 3</label>
          <input
            type="text"
            name="designc"
            className="form-control"
            value={designc}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 4</label>
          <input
            type="text"
            name="designd"
            className="form-control"
            value={designd}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <button className="btn btn-outline-info">Save</button>
    </form>
  );
};

export default SolUpdateForm;
