import { Card, Button, Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const imgStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  lineHeight: "160px",
  textAlign: "center",
};

const { Meta } = Card;

const DataCenters = () => {
  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669116296/tico-solutions-tabs/industrial-data-centers-header_sse7wn.jpg"
              alt="TICO and the many industries we supply to for noise and vibration isolations"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Data Centers</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-dark" href="/industries">
                <span>Industries</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Data Centers
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-5 text-danger">
              Products used within Data Centers
            </h1>
            <p className="lead p-0 mt-2 mb-5">
              TICO has a long been the vibration control material of choice
              amongst Designers and Engineers alike. The TICO range of products
              are available off-the-shelf and manufactured bespoke to meet ever
              demanding environments – all of which are designed to attenuate
              noise, vibration, movement and impact within Structures,
              Construction, Power, HVAC, M&E and other type of installations.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-md-center mb-5 pb-5">
          <div className="col-12 mt-5">
            <h3 className="text-center">Typical Applications</h3>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999005/tico-solutions-tabs/Datacenter-applications-foundations_xhyvjj.png"
                alt="Tico's foundation isolation products"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999006/tico-solutions-tabs/Datacenter-applications-access-floors_dquz9t.png"
                alt="Tico's raised and access floor materials"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999006/tico-solutions-tabs/Datacenter-applications-plantrooms_xvmkpb.png"
                alt="Noise isolation for plantrooms"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999004/tico-solutions-tabs/Datacenter-applications-hvac_vszzax.png"
                alt="HVAC products to reduce vibration and noise"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999004/tico-solutions-tabs/Datacenter-applications-electrical_zqo7kv.png"
                alt="reduce noise and vibration from electrical items"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999005/tico-solutions-tabs/Datacenter-applications-power_czq3ru.png"
                alt="fight vibration with Tico products"
              />
            </div>
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1676999006/tico-solutions-tabs/Datacenter-applications-pipe-systems_eostmt.png"
                alt="pipe systems"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 pb-3">
            <div>
              <img
                style={imgStyle}
                src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1659620029/tico-solutions-tabs/lift-for-lifts-escalators_gwtpfm.png"
                alt="reduce lift vibration and noise"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5 mb-5 bgstill">
        <div className="container">
          <div className="col-12 pt-3 pb-3">
            <p className="lead text-center p-5 mt-5 mb-5">
              Our technical teams are on hand to provide advice on the most
              suitable TICO Anti-Vibration solutions and detailed analysis of
              the level of vibration attenuation you can expect. TICO solutions
              are durable, manufactured to be maintenance free and last for
              decades. TICO can provide solutions for wide-ranging requirements,
              environments and technical infrastructure.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        {/* row 1 of products */}
        <div className="row">
          <div className="col-12 mt-5">
            <h3>Suitable Products</h3>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Anti-vibration material for general machine mounting applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852778/arssgyddqarkbccmmxyh.jpg"
                />
              }
              actions={[
                <Link to={`/sol/general-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/general-machine-mounting`}>
                <Meta
                  title="General Machine Mounting"
                  description="TICO's general purpose pads are high performance machinery mounting materials, which are manufactured from a..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Vibration isolation material suitable for low frequency applications"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852782/izlnvdbn6kfy18zxleu1.jpg"
                />
              }
              actions={[
                <Link to={`/sol/low-frequency-machine-mounting`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/low-frequency-machine-mounting`}>
                <Meta
                  title="Low Frequency Machine Mounting"
                  description="There are certain applications where a very high degree of isolation is required &#45; in particular, applications..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico levelling mounts"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1665504300/itksbvk38kahiv3igvvh.jpg"
                />
              }
              actions={[
                <Link to={`/sol/adjustamounts`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/tico-adjustamounts`}>
                <Meta
                  title="Levelling Mounts"
                  description="TICO Adjustamounts (levelling mounts) are specially designed for mounting machines and structures where..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 2 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico-pipe-support-clamp-grip-type"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663851237/sk5mjrrlv41ayrtdvktb.jpg"
                />
              }
              actions={[
                <Link to={`/sol/insulated-u-bolt-pipe-support-grip`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`sol/insulated-u-bolt-pipe-support-grip`}>
                <Meta
                  title="Pipe Support Clamp - Grip Type"
                  description="Configuration enables the pipe to be gripped and supported while accommodating small axial and torsional movements..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Pipe Support Clamp Liners"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663847789/bbtjloddl6yei0y0vfev.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-strip-liner-scl`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-strip-liner-scl`}>
                <Meta
                  title="Pipe Clamp Strip Liner - S/CL"
                  description="Manufactured from our well known TICO S rubber bonded cork material and is red/brown in colour..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="clamp block - single pipe system"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663846473/se6q6kcmvjn6s8vmp4u3.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pipe-clamp-block-single`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pipe-clamp-block-single`}>
                <Meta
                  title="Pipe Clamp Block - Single"
                  description="TICO Clamp blocks absorb shock and vibration caused by fluid velocity and turbulence in pipes..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 3 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="access floor"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664785168/mzbzdfyeycrugkwpamom.jpg"
                />
              }
              actions={[
                <Link to={`/sol/access-floor-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/access-floor-isolation`}>
                <Meta
                  title="Access floor"
                  description="Typically consisting of a pedestal and cradle design, TICO pads are an important component to stop the transfer..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="concrete floor isolation"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663855658/f3e3hagot4u9leqmbobw.jpg"
                />
              }
              actions={[
                <Link to={`/sol/concrete-floating-floor-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/concrete-floating-floor-isolation`}>
                <Meta
                  title="Wet Floors"
                  description="Designed to create low frequency isolating floating floors as used in gyms, music studios etc..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico plinth installation solutions"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1663852225/br86suho0oykpdgunogl.jpg"
                />
              }
              actions={[
                <Link to={`/sol/plinth-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/plinth-installation`}>
                <Meta
                  title="Plinth Installation"
                  description="The main reasons for using a foundation block are to provide additional mass to improve the rigidity of the machine structure..."
                />
              </Link>
            </Card>
          </div>
        </div>

        {/* row 4 of products */}

        <div className="row">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="bolt isolation between insert and machine base"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1669220979/tacidxfz5e0bpjnr8y7g.jpg"
                />
              }
              actions={[
                <Link to={`/sol/bolt-isolation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/bolt-isolation`}>
                <Meta
                  title="Bolt Isolation"
                  description="Bolt Isolation prevents transmission of machine noise and vibration through the bolt to the surrounding..."
                />
              </Link>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="Tico pit installation anti-vibration material"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1664785151/jcifvggszceqhck96ehs.jpg"
                />
              }
              actions={[
                <Link to={`/sol/pit-installation`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/sol/pit-installation`}>
                <Meta
                  title="Pit installation"
                  description="Anti-vibration material for use in Foundation Inertia Block (pit and plinth) installations across a wide variety of industries...."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCenters;
