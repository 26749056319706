import React, { useState } from "react";
import { Menu } from "antd";
import { stack as ReactHamburgerMenu } from "react-burger-menu";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/compat/app";

import "./hamburger.scss";
import ticoLogoReg from "../../images/Tico-Logo_registered.svg";

const { SubMenu, Item } = Menu;

const HamburgerMenu = () => {
  let { user } = useSelector((state) => ({ ...state }));
  let dispatch = useDispatch();
  let history = useHistory();

  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    history.push("/login");
  };

  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };

  return (
    <div className="hamburger-menu">
      <Link to="/">
        <div
          style={{
            float: "left",
            width: "90px",
            height: "10px",
            padding: "10px",
          }}
        >
          <img src={ticoLogoReg} alt="Tico company logo" />
        </div>
      </Link>
      <ReactHamburgerMenu
        right
        isOpen={isMenuOpen}
        onStateChange={handleStateChange}
      >
        <Menu style={{ width: 256 }} mode="inline">
          <Item key="home">
            <Link onClick={() => handleCloseMenu()} to="/">
              HOME
            </Link>
          </Item>
          <Item key="solutions">
            <Link onClick={() => handleCloseMenu()} to="/solutions">
              SOLUTIONS
            </Link>
          </Item>
          <SubMenu key="SubMenu2" title="PRODUCTS">
            <Item key="product-parameters">
              <Link onClick={() => handleCloseMenu()} to="/product-parameters">
                Filter by Product Parameters
              </Link>
            </Item>
            <Item key="product-application">
              <Link onClick={() => handleCloseMenu()} to="/product-application">
                Filter by Product Application
              </Link>
            </Item>
          </SubMenu>
          <Item key="industries">
            <Link onClick={() => handleCloseMenu()} to="/industries">
              INDUSTRIES
            </Link>
          </Item>
          <Item key="services">
            <Link onClick={() => handleCloseMenu()} to="/services">
              SERVICES
            </Link>
          </Item>
          <SubMenu key="SubMenu4" title="CONTACT US">
            <Item key="contact-us">
              <Link onClick={() => handleCloseMenu()} to="/contact-us">
                Contact Us
              </Link>
            </Item>
            <Item key="distributors">
              <Link onClick={() => handleCloseMenu()} to="/distributors">
                Distributors
              </Link>
            </Item>
          </SubMenu>
          <Item key="about-us">
            <Link onClick={() => handleCloseMenu()} to="/about-us">
              ABOUT US
            </Link>
          </Item>
          <Item key="Buy Online">
            <a
              onClick={() => handleCloseMenu()}
              href="https://tico-online.com/"
              target="_blank"
              rel="noreferrer"
            >
              BUY ONLINE
            </a>
          </Item>
          {user && (
            <SubMenu key="SubMenu5" title="Account">
              {user && user.role === "Administrator" && (
                <Item key="admin-menu">
                  <Link onClick={() => handleCloseMenu()} to="/admin/sols">
                    Admin Menu
                  </Link>
                </Item>
              )}
              <Item key="register">
                <Link onClick={() => handleCloseMenu()} to="/register">
                  reg
                </Link>
              </Item>
              <Item key="login">
                <Link onClick={() => handleCloseMenu()} to="/login">
                  login
                </Link>
              </Item>
              <Item key="logout" onClick={logout}>
                logout
              </Item>
            </SubMenu>
          )}
        </Menu>
      </ReactHamburgerMenu>
    </div>
  );
};

export default HamburgerMenu;
