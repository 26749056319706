import React, { useState } from "react";
import { Select, Radio } from "antd";

const { Option } = Select;

const SolCreateForm = ({
  handleSubmit,
  handleChange,
  handleFileChange,
  setValues,
  values,
  handleSolutionChange,
  handleVideoFileChange,
  subsolOptions,
  showSubsol,
}) => {
  // destruc
  const {
    title,
    description,
    solutions,
    subsols,
    featurea,
    featureb,
    featurec,
    featured,
    designa,
    designb,
    designc,
    designd,
    sketchfab,
  } = values;
  const [uploadComponentValue, setUploadComponentValue] = useState(1);

  const onChange = (e) => {
    const radioValue = e.target.value;
    setUploadComponentValue(radioValue);
    if (radioValue === 1) {
      handleVideoFileChange({ target: { name: "video", files: [""] } });
    } else if (radioValue === 2) {
      handleChange({ target: { name: "sketchfab", value: "" } });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          name="title"
          className="form-control"
          value={title}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label>Description</label>
        <input
          type="text"
          name="description"
          className="form-control"
          value={description}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label>Solution</label>
        <select
          name="solution"
          className="form-control"
          onChange={handleSolutionChange}
        >
          <option>Please select</option>
          {solutions.length > 0 &&
            solutions.map((u) => (
              <option key={u._id} value={u._id}>
                {u.name}
              </option>
            ))}
        </select>
      </div>
      {showSubsol && (
        <div>
          <label>Sub Solutions</label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Please select"
            value={subsols}
            onChange={(value) => setValues({ ...values, subsols: value })}
          >
            {subsolOptions.length &&
              subsolOptions.map((b) => (
                <Option key={b._id} value={b._id}>
                  {b.name}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Key Feature 1</label>
          <input
            type="text"
            name="featurea"
            className="form-control"
            placeholder="At least 1 key feature must be entered"
            value={featurea}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 2</label>
          <input
            type="text"
            name="featureb"
            className="form-control"
            value={featureb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 3</label>
          <input
            type="text"
            name="featurec"
            className="form-control"
            value={featurec}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Key Feature 4</label>
          <input
            type="text"
            name="featured"
            className="form-control"
            value={featured}
            onChange={handleChange}
          />
        </div>
      </div>
      <br />
      <div className="row">
        <div className="form-group col-md-6">
          <label>Design Requirement 1</label>
          <input
            type="text"
            name="designa"
            className="form-control"
            placeholder="At least 1 design requirement must be entered"
            value={designa}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 2</label>
          <input
            type="text"
            name="designb"
            className="form-control"
            value={designb}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 3</label>
          <input
            type="text"
            name="designc"
            className="form-control"
            value={designc}
            onChange={handleChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label>Design Requirement 4</label>
          <input
            type="text"
            name="designd"
            className="form-control"
            value={designd}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-4 col-md-12">
          <label>Choose 3D component type</label>
          <div className="mt-2">
            <Radio.Group onChange={onChange} value={uploadComponentValue}>
              <Radio value={1}>Sketchfab Embed Link</Radio>
              <Radio value={2}>mp4 File</Radio>
            </Radio.Group>
          </div>
        </div>
        {uploadComponentValue === 1 ? (
          <div className="form-group col-md-12">
            <label>Sketchfab Embed Link</label>
            <input
              type="text"
              name="sketchfab"
              className="form-control"
              value={sketchfab}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {uploadComponentValue === 2 ? (
          <div className="form-group col-md-12">
            <label className="btn btn-primary mr-2">
              mp4 File upload
              <input
                name="video"
                type="file"
                hidden
                accept="video/mp4"
                onChange={handleVideoFileChange}
              />
            </label>
          </div>
        ) : null}
      </div>
      <div className="row mt-4 pl-3">
        <label className="btn btn-primary mr-2">
          Brochure upload
          <input
            name="brochure"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
        <label className="btn btn-primary mr-2">
          Datasheet upload
          <input
            name="datasheet"
            type="file"
            hidden
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </label>
      </div>
      <br />
      <button className="btn btn-outline-info">Save</button>
    </form>
  );
};

export default SolCreateForm;
