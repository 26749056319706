import React, { useEffect, useState } from "react";
import { getSolsByCount, getSolsByFilter } from "../functions/sol";
import { getSolutions } from "../functions/solution";
import { getSubsols } from "../functions/subsol";
import SolCard from "../components/cards/SolCard";

import { Checkbox, Menu, Breadcrumb } from "antd";

const { SubMenu } = Menu;

const Solutions = (props) => {
  const solutionsSlug = props.location.state
    ? props.location.state.solution
    : null;

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedSubCategories, setCheckedSubCategories] = useState([]);

  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch data for category selection panel
    getSolutions().then((response) => {
      setCategories(response.data);
    });
    getSubsols().then((response) => {
      setSubcategories(response.data);
      setFilteredSubcategories(response.data);
    });
  }, []);

  useEffect(() => {
    if (!solutions.length && categories.length) {
      const categoryIDs = categories
        .filter((category) => category.slug === solutionsSlug)
        .map((filteredCategories) => filteredCategories._id);
      if (categoryIDs.length) {
        getSolsByFilter({ solution: [categoryIDs[0]] }).then((res) => {
          setSolutions(res.data);
        });
        setCheckedCategories(categoryIDs);
      } else {
        // Load all solution items
        getSolsByCount(36).then((response) => {
          setSolutions(response.data);
          setLoading(false);
        });
      }
    }
  }, [categories]);

  useEffect(() => {
    if (checkedCategories.length) {
      const updatedSubCategoryChecks = [];
      const subSolCategories = subcategories.filter((item) => {
        const isChecked = checkedCategories.includes(item.parent);
        if (isChecked && filteredSubcategories.includes(item._id)) {
          updatedSubCategoryChecks.push(item._id);
        }
        return isChecked;
      });
      setCheckedSubCategories(updatedSubCategoryChecks);
      setFilteredSubcategories(subSolCategories);
    } else {
      setFilteredSubcategories(subcategories);
    }
  }, [subcategories, checkedCategories]);

  const fetchSols = (arg) => {
    getSolsByFilter(arg).then((res) => {
      setSolutions(res.data);
    });
  };

  // Load solution categories
  const showSolutions = () =>
    categories.map((u) => (
      <div key={u._id}>
        <Checkbox
          onChange={handleCheckSolutions}
          className="pb-2 pl-4 pr-4"
          value={u._id}
          name="solution"
          checked={checkedCategories.includes(u._id)}
        >
          {u.name}
        </Checkbox>
        <br />
      </div>
    ));

  // check for selected solutions
  const handleCheckSolutions = (e) => {
    let inTheStateSolutions = [...checkedCategories];
    let justCheckedSolutions = e.target.value;
    let foundInTheStateSolutions =
      inTheStateSolutions.indexOf(justCheckedSolutions);

    if (foundInTheStateSolutions === -1) {
      inTheStateSolutions.push(justCheckedSolutions);
    } else {
      inTheStateSolutions.splice(foundInTheStateSolutions, 1);
    }
    setCheckedCategories(inTheStateSolutions);
    if (!inTheStateSolutions.length) {
      // Load all solution items
      getSolsByCount(18).then((response) => {
        setSolutions(response.data);
        setLoading(false);
      });
    } else {
      fetchSols({ solution: inTheStateSolutions });
    }
  };

  // Load Subcategories
  const showSubsols = () =>
    filteredSubcategories.map((b) => (
      <div key={b._id}>
        <Checkbox
          onChange={handleCheckSubsols}
          // onClick={() => handleSub(s)}
          className="pb-2 pl-4 pr-4"
          value={b._id}
          name="subsol"
          checked={checkedSubCategories.includes(b._id)}
        >
          {b.name}
        </Checkbox>
        <br />
      </div>
    ));

  // check for sub categories
  const handleCheckSubsols = (e) => {
    let inTheState = [...checkedSubCategories];
    let justChecked = e.target.value;
    let foundInTheState = inTheState.indexOf(justChecked);
    if (foundInTheState === -1) {
      inTheState.push(justChecked);
    } else {
      inTheState.splice(foundInTheState, 1);
    }
    setCheckedSubCategories(inTheState);
    if (inTheState.length) {
      fetchSols({ subsol: inTheState });
    } else if (checkedCategories.length) {
      fetchSols({ solution: checkedCategories });
    } else {
      getSolsByCount(26).then((response) => {
        setSolutions(response.data);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div className="container-fluid p-0 mt-5">
        <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mb-3">Loading...</h4>
            ) : (
              <h1 className="text-danger mb-3">Solutions</h1>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse bread-margin">
            <Breadcrumb className="mt-2">
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                Solutions
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row mt-5">
          <div className="col-md-3">
            <h4 className="h4-pad">Filter Solutions</h4>
            <hr />
            <Menu defaultOpenKeys={["1", "2"]} mode="inline">
              <SubMenu
                key="1"
                title={
                  <span>
                    <h6 className="text-danger">Solution Categories</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showSolutions()}</div>
              </SubMenu>
              <SubMenu
                key="2"
                title={
                  <span>
                    <h6 className="text-danger">Solution Sub Categories</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showSubsols()}</div>
              </SubMenu>
            </Menu>
          </div>
          <div className="col-md-9 pt-2">
            <div className="row pb-5">
              {solutions.map((l) => (
                <div key={l._id} className="col-sm-6 col-md-4 mt-3">
                  <SolCard sol={l} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Solutions;
