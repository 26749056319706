import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { getSol } from "../functions/sol";
import SingleSol from "../components/cards/SingleSol";
import { getSolRelated } from "../functions/sol";
import SolCard from "../components/cards/SolCard";
import SolBrochureModal from "../components/modals/SolBrochureModal";
import SolGeneralModal from "../components/modals/SolGeneralModal";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Solution = ({ match }) => {
  const [sol, setSol] = useState({});
  const [related, setRelated] = useState([]);

  const { slug } = match.params;

  useEffect(() => {
    loadSingleSol();
  }, [slug]);

  const loadSingleSol = () => {
    getSol(slug).then((res) => {
      setSol(res.data);
      getSolRelated(res.data._id).then((res) => setRelated(res.data));
    });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mx-0 angle-product mt-3 position-absolute"></div>
      <div className="row mt-5">
        <SingleSol sol={sol} />
      </div>

      {sol.brochure || sol.datasheet ? (
        <div className="container">
          <div className="row flex-column d-flex align-items-center">
            <div className="col text-center pt-5 pb-5">
              <h2 className="text-danger">More Information</h2>
            </div>
            <div className="row pb-5 text-center justify-content-center">
              {sol.brochure && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": sol.datasheet,
                    "col-md-4": !sol.datasheet,
                  })}
                >
                  <SolBrochureModal sol={sol} />
                </div>
              )}
              {sol.datasheet && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": sol.brochure,
                    "col-md-4": !sol.brochure,
                  })}
                >
                  <SolGeneralModal sol={sol} />
                </div>
              )}
            </div>
          </div>
          <div className="row justify-content-center pb-5">
            <Link to="../contact-us">
              <Button className="mb-4 btn-edit" size="large" type="danger">
                Contact TICO for more information
              </Button>
            </Link>
          </div>
        </div>
      ) : null}
      <div
        className={classNames("container-fluid", {
          "bg-white": !(sol.brochure || sol.datasheet),
          bgstill: sol.brochure || sol.datasheet,
        })}
      >
        <div className="container-fluid p-0 bgstill">
          <div className="row bgstill">
            <div className="col text-center pt-5 pb-3">
              <h3>Related Items</h3>
            </div>
          </div>
          <div className="container">
            <div className="row bgstill pb-5 justify-content-md-center">
              {related.length ? (
                related.map((a) => (
                  <div key={a._id} className="col-md-3 pb-3">
                    <SolCard sol={a} />
                  </div>
                ))
              ) : (
                <div className="text-center col">No Items Available</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
