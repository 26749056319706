import React from "react";
import { Link } from "react-router-dom";

const AdminNav = () => (
  
  <nav>
    <h6 className="text-danger pl-3">Menu</h6>
    <ul className="nav flex-column">
      <li className="nav-item">
        <Link to="/admin/sols" className="nav-link text-dark">
          View All Solutions
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sol" className="nav-link text-dark">
          Create New Solution
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/solution" className="nav-link text-dark">
          Create Solution Category
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sub-solution" className="nav-link text-dark">
          Create Solution Sub Category
        </Link>
      </li>

      <li className="nav-item  pt-5">
        <Link to="/admin/products" className="nav-link text-dark">
          View All Products
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/product" className="nav-link text-dark">
          Create New Product
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/category" className="nav-link text-dark">
          Create Product Category
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/sub" className="nav-link text-dark">
          Create Product Sub Category
        </Link>
      </li>

    </ul>
  </nav>
);

export default AdminNav;
