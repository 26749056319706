import axios from "axios";

     export const createSol = async (sol, authtoken) =>
     await axios.post(`${process.env.REACT_APP_API}/sol`, sol, {
         headers: {
             authtoken,
             'Content-Type': 'multipart/form-data'
         },
     });

     export const getSolsByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/sols/${count}`);

  export const removeSol = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/sol/${slug}`, {
      headers: {
          authtoken,
      },
  });

  export const getSol = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/sol/${slug}`);

  export const updateSol = async (slug, sol, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/sol/${slug}`, sol, {
      headers: {
          authtoken,
      },
  });

  export const getSols = async (sort, order, page) =>
  await axios.post(`${process.env.REACT_APP_API}/sols`, {
      sort,
      order,
      page,
  });

  export const getSolsCount = async () =>
  await axios.get(`${process.env.REACT_APP_API}/sols/total`);

  export const getSolRelated = async (solId) =>
  await axios.get(`${process.env.REACT_APP_API}/sol/related/${solId}`);

  export const getSolsByFilter = async (arg) =>
  await axios.post(`${process.env.REACT_APP_API}/sol/filters`, arg);