import React from "react";

export default function SimpleMap() {
  return (
    <>
      <div className="container-fluid p-0 mt-5">
        <div className="row mx-0 angle-product mt-5 position-absolute"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-danger mb-3">Distributors</h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <div>
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1jsL6bg56cgZWQatlCspFnVLcao8&ehbc=2E312F"
                title="Map of Tico distributers across the globe"
                width="100%"
                height="480"
              ></iframe>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col pt-4">
            <p className="lead">
              Please use the map above to contact your nearest TICO sales
              office. For all other enquiries please visit our contact page on
              this website.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
