import React, { useState, useEffect } from "react";
import { getSolsByCount, getSolsByFilter } from "../../functions/sol";
import { getSolution, getSolutions } from "../../functions/solution";
import { getSubsols } from "../../functions/subsol";
import SolCard from "../../components/cards/SolCard";
import { Menu, Checkbox } from "antd";

const { SubMenu } = Menu;

const SolutionHome = ({ match }) => {
  const [sols, setSols] = useState([]);
  const [loading, setLoading] = useState(false);
  const [solution, setSolution] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [subsols, setSubsols] = useState([]);
  const [subsol, setSubsol] = useState([]);
  const [subsolIds, setSubsolIds] = useState([]);

  const { slug } = match.params;

  useEffect(() => {
    loadAllSols();
    getSolutions().then((res) => setSolutions(res.data));
    getSolution(slug).then((res) => {
      // console.log(JSON.stringify(res.data, null, 4));
      setSolution(res.data.solution);
      setSols(res.data.sols);
      setLoading(false);
    });
    // get sol sub solutions
    getSubsols().then((res) => setSubsols(res.data));
  }, []);

  const fetchSols = (arg) => {
    getSolsByFilter(arg).then((res) => {
      setSols(res.data);
    });
  };

  // load default items
  const loadAllSols = () => {
    getSolsByCount(18).then((l) => {
      setSols(l.data);
      setLoading(false);
    });
  };

  //load items by sub category
  const showSubsols = () =>
    subsols.map((b) => (
      <div key={b._id}>
        <Checkbox
          onChange={handleCheckSubsols}
          className="pb-2 pl-4 pr-4"
          value={b._id}
          name="subsol"
          checked={subsolIds.includes(b._id)}
        >
          {b.name}
        </Checkbox>
        <br />
      </div>
    ));

  const handleSubsol = (subsol) => {
    // console.log("SUBSOL", subsol );
    setSubsol(subsol);
    fetchSols({ subsol });
  };

  //check for categories
  const handleCheckSubsols = (e) => {
    //console.log(e.target.value);
    let inTheState = [...subsolIds];
    let justChecked = e.target.value;
    let foundInTheState = inTheState.indexOf(justChecked);

    if (foundInTheState === -1) {
      inTheState.push(justChecked);
    } else {
      inTheState.splice(foundInTheState, 1);
    }
    setSubsolIds(inTheState);
    //console.log(inTheState);
    fetchSols({ subsol: inTheState });
  };

  return (
    <>
      <div className="container-fluid p-0">
        <div className="row mx-0 angle-product mb-n5"></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            {loading ? (
              <h4 className="text-center p-3 mt-5 mb-5">Loading...</h4>
            ) : (
              <h1 className="text-danger mt-n5 mb-5">{solution.name}</h1>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 pt-2">
            <h4>Filter Options</h4>
            <hr />
            <Menu defaultOpenKeys={["1", "2"]} mode="inline">
              <SubMenu
                key="1"
                title={
                  <span>
                    <h6 className="text-danger">Solution Types</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>
                  <span>
                    <p className="pl-4 pt-3">
                      <a href="/solution/structural-and-architectural">
                        Structural &amp; Architectural
                      </a>
                    </p>
                    <p className="pl-4 pt-3">
                      <a href="/solution/plant-and-machinery-isolation">
                        Plant &amp; Machinery Isolation
                      </a>
                    </p>
                    <p className="pl-4 pt-3">
                      <a href="/solution/pipe-systems">Pipe Systems</a>
                    </p>
                    <p className="pl-4 pt-3">
                      <a href="/solution/thermal-breaks">Thermal Breaks</a>
                    </p>
                  </span>
                </div>
              </SubMenu>

              <SubMenu
                className="pt-4"
                key="2"
                title={
                  <span>
                    <h6 className="text-danger">Solution Groups</h6>
                  </span>
                }
              >
                <div style={{ marginTop: "-10px" }}>{showSubsols()}</div>
              </SubMenu>
            </Menu>
          </div>

          <div className="col-md-9 pt-2">
            <div className="row pb-5">
              {sols.map((l) => (
                <div key={l._id} className="col-md-4 mt-3">
                  <SolCard sol={l} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SolutionHome;
