import { Image, Button, Breadcrumb, Card } from "antd";
import React, { useEffect, useRef } from "react";
import YoutubeEmbed from "../components/videos/YoutubeEmbed";
import { Link } from "react-router-dom";

const RecentProject = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const { Meta } = Card;

  return (
    <>
      <div className="container-fluid main p-0">
        <div className="row mx-0">
          <div className="col p-0 main stackParent">
            <img
              className="img-fluid head-img"
              width="100%"
              height="500px"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1666713736/tico-solutions-tabs/Banner-botanic-link-belfast_njsqpg.jpg"
              alt="Supply of TICO structural bearings to a Farrans Construction project at the Botanic Link in Belfast, Ireland"
              preview={false}
            />
            <div className="stack-Txt">
              <div className="stack-Txt-child">Projects</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 mt-n5">
        <div className="row">
          <div className="white-angle"></div>
        </div>
      </div>

      <div className="container mt-n5 p-0">
        <div className="row d-flex">
          <div className="col-md-12 d-flex flex-row-reverse">
            <Breadcrumb>
              <Breadcrumb.Item className="text-dark" href="/">
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text-danger">
                TICO Project
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container p-0">
        <div className="row">
          <div className="col">
            <h1 className="mt-5 text-danger">TICO Project</h1>
            <h2 className="text-danger">BOTANIC LINK - Belfast, Ireland</h2>
            <p className="lead p-0 mt-2">
              The Botanic Link is a highly technical and complex project
              creating 253 student bed spaces, the structure was built directly
              above the main railway route into the city centre.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="row mb-5">
        <div className="container p-0">
          <div className="col-12">
            <p className="lead">
              The TICO structural bearing units were designed to operate with a
              resonant frequency of 8Hz which were required to isolate the
              effects of train-induced vibration of around 40-60Hz. The bearings
              were encased between steel plates with added shear studs for
              installation.
            </p>
            <p className="lead">
              The bearing units contained failsafe bars and lateral restraints
              and were supplied complete with Epigrip coating, fire blankets and
              an environmental shroud to ensure the longevity of the bearings.
            </p>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div className="row mb-5">
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid pt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1662462001/tico-solutions-tabs/Recent-project-view-of-structural-bearing-pads_ntqmfs.png"
              alt="TICO structural bearings pads CV/CA before being incased in the bearing unit"
            />
            <p className="pt-3 pb-5">
              46 encased TICO CV/CA structural bearings were used to support a
              new student accommodation development over an existing rail
              structure.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <Image
              className="img-fluid pt-3"
              src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1662462001/tico-solutions-tabs/Recent-project-view-of-inclosed-structural-bearing-units_xup9o8.png"
              alt="Complete structural bearing unit"
            />
            <p className="pt-3">
              The bearings were being utilised to stop the vibrations from the
              railway below the development becoming problematic for the
              residents above.
            </p>
          </div>
        </div>
      </div>

      <div className="row text-center mt-5 mb-5 bgstill">
        <div className="container">
          <h4 className="pt-5 mt-5 text-danger">Clients:</h4>
          <h4 className="pt-1">Farrans Construction</h4>
          <h4 className="pt-1">Manson Architects &amp; Planners</h4>
          <h4 className="pt-1 mb-5 pb-5">Etive Consulting Engineers Limited</h4>
        </div>
      </div>

      <div className="container">
        <div className="row text-center">
          <div className="col mt-5">
            <h2 className="text-danger pt-5">Component Animation</h2>
            <p className="lead">
              Short animation showing every component of a TICO structural
              bearing assembly.
            </p>
          </div>
        </div>
        <div className="row mb-5 pb-5 justify-content-md-center">
          <div className="col-md-8">
            <video
              style={{
                maxWidth: "100%",
                width: "800px",
                margin: "0 auto",
              }}
              playsInline
              loop
              muted
              controls
              alt="Animation of all components required in a TICO structural bearing assembly"
              src="https://res.cloudinary.com/dkfjd6cdn/video/upload/v1676890064/tico-solution-video/1676890062452.mp4"
              ref={videoEl}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row text-center">
          <div className="col mt-5">
            <h2 className="text-danger pt-5">Video</h2>
            <p className="lead">
              Farrans Construction video highlighing the early stages of
              construction on the Botanic Link site.
            </p>
          </div>
        </div>
        <div className="row mb-5 pb-5 justify-content-md-center">
          <div className="col-md-8">
            <YoutubeEmbed embedId="GCVWXNCQpWY" />
          </div>
        </div>
        <div className="row">
          <div className="col pt-5">
            <h2 className="text-danger text-center">Product Used</h2>
            <p className="pt-1 text-center lead pl-5 pr-5">
              TICO CV/CA is manufactured from vulcanised laminates of
              polychloroprene-based elastomer, enhanced with cellular particles
              and alternated with reinforcing piles of high-tensile fabric.
            </p>
            <p className="pt-1 text-center lead pl-5 pr-5">
              Click CV/CA product image below to see more information, product
              brochure and technical datasheet.
            </p>
          </div>
        </div>
        <div className="row  justify-content-center">
          <div className="col-md-4 mb-4">
            <Card
              cover={
                <img
                  alt="bearing assemblies"
                  src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1667488139/avy2sucuzozrb3quf7bk.jpg"
                />
              }
              actions={[
                <Link to={`/product/cvca-structural-bearing`}>
                  <Button type="primary" className="float-left ml-4">
                    View Product
                  </Button>
                </Link>,
              ]}
            >
              <Link to={`/product/cvca-structural-bearing`}>
                <Meta
                  title="CV/CA - Structural Bearing"
                  description="TICO CV/CA bearings high load applications have a maximum recommended working stress of 7000 kN/m2."
                />
              </Link>
            </Card>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col mt-5">
          <Image
            className="img-fluid"
            src="https://res.cloudinary.com/dkfjd6cdn/image/upload/v1662467091/tico-solutions-tabs/botanic-link-belfast-banner_w4ayq8.jpg"
            alt="Laddish bearings designed for large scale forging hammers"
          />
        </div>
      </div>
    </>
  );
};

export default RecentProject;
