
// import * as firebase from "firebase/app"; // old way, wont work anymore
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// firebase config
const config = {
  apiKey: "AIzaSyCHJo6_uWCHvj5d4FCO9BWLnZAFPj7ob5E",
  authDomain: "tico-solutions.firebaseapp.com",
  projectId: "tico-solutions",
  messagingSenderId: "926757646442",
  appId: "1:926757646442:web:da33c33c4bf32ae0b15a13",
};
// initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
// export
// export default firebase;
export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
